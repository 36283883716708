<template>
  <div class="my-5">
    <v-form v-model="isValid" ref="form" @submit.prevent>
      <v-row>
        <v-col cols="12" md="6">
          <label v-html="`${$t('profile.sign')}`" />
          <v-text-field
            class="mt-2"
            v-model="userData.person.personInfos[4].value"
            :placeholder="`${$t('profile.sign')}`"
            outlined
            :rules="signRules"
            :disabled="isFieldDisabled"
            @input="
              val =>
                (userData.person.personInfos[4].value = userData.person.personInfos[4].value.toUpperCase())
            "
          ></v-text-field>
        </v-col>
        <v-col class="d-none d-md-inline" cols="0" md="6"></v-col>
        <v-col cols="12" md="6">
          <label v-html="`${$t('profile.contacts.company')} *`" /><v-tooltip
            color="secondary"
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">
                $info
              </v-icon>
            </template>
            <span>{{ $t("profile.contacts.companyTooltip") }}</span>
          </v-tooltip>
          <v-text-field
            class="mt-2"
            v-model="userData.person.company"
            :placeholder="`${$t('profile.contacts.legalName')}`"
            outlined
            :rules="companyRules"
            :disabled="isFieldDisabled"
            @input="
              val =>
                (userData.person.company = userData.person.company.toUpperCase())
            "
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <label v-html="`${$t('profile.contacts.secondaryEmail')}`" />
          <v-text-field
            class="mt-2"
            v-model="userData.contact.email2"
            :rules="secondaryEmailRules"
            :placeholder="`${$t('profile.contacts.secondaryEmailInfo')}`"
            outlined
            :disabled="isFieldDisabled"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <label v-html="`${$t('profile.contacts.mobilePhone')} *`" />
          <v-text-field
            class="mt-2"
            v-model="userData.phone"
            :rules="phoneRules"
            :placeholder="`${$t('profile.contacts.mobilePhone')}`"
            outlined
            :disabled="isFieldDisabled"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <label v-html="`${$t('profile.contacts.workPhone')}`" />
          <v-text-field
            class="mt-2"
            v-model="userData.contact.workPhone"
            :rules="telephoneNumber"
            :placeholder="`${$t('profile.contacts.workPhone')}`"
            outlined
            :disabled="isFieldDisabled"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <label v-html="'Fax'" />
          <v-text-field
            class="mt-2"
            v-model="userData.contact.faxPhone"
            :rules="workPhoneRules"
            placeholder="Fax"
            outlined
            :disabled="isFieldDisabled"
          ></v-text-field>
        </v-col>
        <v-col cols="12"
          ><h5>{{ $t("profile.legalRepresentative") }}</h5></v-col
        >
        <v-col cols="12" sm="4" md="3">
          <label v-html="`${$t('profile.firstName')} *`" />
          <v-text-field
            class="mt-2"
            v-model="userData.firstName"
            :rules="nameRules"
            :placeholder="`${$t('profile.firstName')}`"
            outlined
            :disabled="isFieldDisabled"
            @input="
              val => (userData.firstName = userData.firstName.toUpperCase())
            "
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <label v-html="`${$t('profile.lastName')} *`" />
          <v-text-field
            class="mt-2"
            v-model="userData.lastName"
            :rules="nameRules"
            :placeholder="`${$t('profile.lastName')}`"
            outlined
            :disabled="isFieldDisabled"
            @input="
              val => (userData.lastName = userData.lastName.toUpperCase())
            "
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <label v-html="`${$t('profile.birthDate')} *`" />
          <v-menu
            v-model="birthDateCal"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mt-2"
                :value="fomatedBirthDate"
                append-icon="$calendar"
                @click:append="birthDateCal = true"
                placeholder="gg-mm-aaaa"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                :rules="[requiredRules()]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="birthDate"
              :max="maxBirthDate"
              :picker-date.sync="pickerDate"
              locale="it-IT"
              no-title
              @input="dateSelected"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12"
          ><h5>{{ $t("profile.document") }}</h5></v-col
        >
        <v-col cols="12" sm="6" md="4">
          <label v-html="`${$t('profile.documentExpirationDate')} *`" />
          <v-menu
            v-model="endDateDocCal"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mt-2"
                :value="fomatedEndDateDoc"
                append-icon="$calendar"
                @click:append="endDateDocCal = true"
                placeholder="gg-mm-aaaa"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                :rules="[requiredRules()]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDateDoc"
              locale="it-IT"
              no-title
              @input="fileDateSelected"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <label v-html="`${$t('profile.documentNumber')} *`" />
          <v-text-field
            class="mt-2"
            v-model="userData.person.personInfos[6].value"
            :rules="documentNumber"
            :placeholder="`${$t('profile.documentNumber')}`"
            outlined
            :disabled="isFieldDisabled"
            @input="
              val =>
                (userData.person.personInfos[6].value = userData.person.personInfos[6].value.toUpperCase())
            "
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <label v-html="`${$t('profile.documentType')} *`" />
          <v-text-field
            class="mt-2"
            v-model="userData.person.personInfos[5].value"
            :placeholder="`${$t('profile.documentType')}`"
            outlined
            :disabled="isFieldDisabled"
            :rules="documentTypeRules"
            @input="
              val =>
                (userData.person.personInfos[5].value = userData.person.personInfos[5].value.toUpperCase())
            "
          ></v-text-field>
        </v-col>
        <v-col cols="12"
          ><h5>{{ $t("profile.registeredOffice") }} **</h5></v-col
        >
        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.address.country')} *`" />
          <v-select
            class="mt-2"
            v-model="userData.billingAddress.countryId"
            @change="countryChanged('billingAddress')"
            :items="nations"
            item-text="name"
            item-value="id"
            :placeholder="`${$t('profile.address.country')}`"
            outlined
            :disabled="isFieldDisabled"
            :rules="[requiredRules()]"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.address.address')} *`" />
          <v-text-field
            class="mt-2"
            v-model="userData.billingAddress.address1"
            :rules="addressRules"
            :placeholder="`${$t('profile.address.address')}`"
            outlined
            :disabled="isFieldDisabled"
            @input="
              val =>
                (userData.billingAddress.address1 = userData.billingAddress.address1.toUpperCase())
            "
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="2">
          <label v-html="`${$t('profile.address.addressNumber')} *`" />
          <v-text-field
            class="mt-2"
            v-model="userData.billingAddress.addressNumber"
            :rules="[requiredRules()]"
            :placeholder="`${$t('profile.address.addressNumber')}`"
            outlined
            :disabled="isFieldDisabled"
            @input="
              val =>
                (userData.billingAddress.addressNumber = userData.billingAddress.addressNumber.toUpperCase())
            "
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="2">
          <label v-html="`${$t('profile.address.postalcode')} *`" />
          <v-text-field
            class="mt-2"
            v-model="userData.billingAddress.postalcode"
            :rules="postalcodeRules"
            :placeholder="`${$t('profile.address.postalcode')}`"
            outlined
            :disabled="isFieldDisabled"
            @input="
              val =>
                (userData.billingAddress.postalcode = userData.billingAddress.postalcode.toUpperCase())
            "
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="3">
          <label v-html="`${$t('profile.address.city')} *`" />
          <v-text-field
            class="mt-2"
            v-model="userData.billingAddress.city"
            :rules="[requiredRules()]"
            :placeholder="`${$t('profile.address.city')}`"
            outlined
            :disabled="isFieldDisabled"
            @input="
              val =>
                (userData.billingAddress.city = userData.billingAddress.city.toUpperCase())
            "
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="5">
          <label v-html="`${$t('profile.address.province')} *`" />
          <v-select
            class="mt-2"
            v-if="userData.billingAddress.countryId == 1"
            v-model="userData.billingAddress.province"
            :items="provinceList"
            item-text="name"
            :placeholder="`${$t('profile.address.province')}`"
            :rules="[requiredRules()]"
            outlined
          ></v-select>
          <v-text-field
            v-else
            class="mt-2"
            :value="userData.billingAddress.province"
            outlined
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="9"></v-col>
        <v-col cols="12" sm="6">
          <label
            v-html="`${$t('profile.contacts.vatSdiCodeExtended')}`"
          /><v-tooltip color="secondary" right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">
                $info
              </v-icon>
            </template>
            <span>{{ $t("profile.contacts.vatSdiCodeTooltip") }}</span>
          </v-tooltip>
          <v-text-field
            class="mt-2"
            v-model="userData.person.vatSdiCode"
            :placeholder="`${$t('profile.contacts.vatSdiCode')}`"
            outlined
            :disabled="isFieldDisabled"
            :rules="vatSdiCodeRules"
            @input="
              val =>
                (userData.person.vatSdiCode = userData.person.vatSdiCode.toUpperCase())
            "
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.contacts.emailCertifiedAlter')}`" />
          <v-text-field
            class="mt-2"
            v-model="userData.person.emailCertified"
            :rules="secondaryEmailRules"
            :placeholder="`${$t('profile.contacts.emailCertifiedAlter')}`"
            outlined
            :disabled="isFieldDisabled"
          ></v-text-field>
        </v-col>
        <v-col cols="12"
          ><div class="d-flex align-center">
            <label>{{ $t("register.user.sameAddress") }}?*</label>
            <v-radio-group v-model="sameAddress" row class="ml-5">
              <v-radio :label="$t('common.yes')" :value="true"></v-radio>
              <v-radio :label="$t('common.no')" :value="false"></v-radio>
            </v-radio-group></div
        ></v-col>

        <v-col cols="12" v-if="!sameAddress"
          ><h5>
            {{ $t("profile.contacts.defaultShippingAddress") }} **
          </h5></v-col
        >
        <v-col cols="12" sm="6" md="3" v-if="!sameAddress"
          ><label
            v-html="`${$t('profile.address.addressName')} *`"/><v-text-field
            class="mt-2"
            v-model="userData.defaultShippingAddress.addressName"
            :rules="[requiredRules()]"
            :placeholder="`${$t('profile.address.addressName')}`"
            outlined
            :disabled="isFieldDisabled"
            @input="
              val =>
                (userData.defaultShippingAddress.addressName = userData.defaultShippingAddress.addressName.toUpperCase())
            "
          ></v-text-field
        ></v-col>
        <v-col cols="6" sm="2" v-if="!sameAddress">
          <label v-html="`${$t('profile.address.country')} *`" />
          <v-select
            class="mt-2"
            v-model="userData.defaultShippingAddress.countryId"
            @change="countryChanged('defaultShippingAddress')"
            :items="nations"
            item-text="name"
            item-value="id"
            :placeholder="`${$t('profile.address.country')}`"
            outlined
            :disabled="isFieldDisabled"
            :rules="[requiredRules()]"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="3" v-if="!sameAddress"
          ><label v-html="`${$t('profile.address.address')} *`"/><v-text-field
            class="mt-2"
            v-model="userData.defaultShippingAddress.address1"
            :rules="addressRules"
            :placeholder="`${$t('profile.address.address')}`"
            outlined
            :disabled="isFieldDisabled"
            @input="
              val =>
                (userData.defaultShippingAddress.address1 = userData.defaultShippingAddress.address1.toUpperCase())
            "
          ></v-text-field
        ></v-col>
        <v-col cols="6" sm="3" md="2" v-if="!sameAddress">
          <label v-html="`${$t('profile.address.addressNumber')} *`" />
          <v-text-field
            class="mt-2"
            v-model="userData.defaultShippingAddress.addressNumber"
            :rules="[requiredRules()]"
            :placeholder="`${$t('profile.address.addressNumber')}`"
            outlined
            :disabled="isFieldDisabled"
            @input="
              val =>
                (userData.defaultShippingAddress.addressNumber = userData.defaultShippingAddress.addressNumber.toUpperCase())
            "
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="3" md="2" v-if="!sameAddress">
          <label v-html="`${$t('profile.address.postalcode')} *`" />
          <v-text-field
            class="mt-2"
            v-model="userData.defaultShippingAddress.postalcode"
            :rules="postalcodeRules"
            :placeholder="`${$t('profile.address.postalcode')}`"
            outlined
            :disabled="isFieldDisabled"
            @input="
              val =>
                (userData.defaultShippingAddress.postalcode = userData.defaultShippingAddress.postalcode.toUpperCase())
            "
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" v-if="!sameAddress">
          <label v-html="`${$t('profile.address.city')} *`" />
          <v-text-field
            class="mt-2"
            v-model="userData.defaultShippingAddress.city"
            :rules="[requiredRules()]"
            :placeholder="`${$t('profile.address.city')}`"
            outlined
            :disabled="isFieldDisabled"
            @input="
              val =>
                (userData.defaultShippingAddress.city = userData.defaultShippingAddress.city.toUpperCase())
            "
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="3" v-if="!sameAddress">
          <label v-html="`${$t('profile.address.province')} *`" />
          <v-autocomplete
            class="mt-2"
            v-if="userData.defaultShippingAddress.countryId == 1"
            v-model="userData.defaultShippingAddress.province"
            :items="provinceList"
            item-text="name"
            :placeholder="`${$t('profile.address.province')}`"
            :rules="[requiredRules()]"
            outlined
          ></v-autocomplete>
          <v-text-field
            v-else
            class="mt-2"
            :value="userData.defaultShippingAddress.province"
            outlined
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="mt-3"
          >*{{ $t("profile.remoteEnrollment") }}
          <a
            class="primary--text text-decoration-none"
            @click.stop="$router.push('/page/diventa-cliente/')"
            >qui</a
          ></v-col
        >
        <v-col cols="12" class="mt-3"
          ><v-file-input
            v-model="file"
            accept="image/jpeg,image/gif,image/png,application/pdf"
            outlined
            @change="addFile"
            :clearable="false"
            persistent-placeholder
            placeholder="Carica documento"
            :rules="documentRules"
            ><template v-slot:selection="{}">
              <span class="primary--text">
                {{ $tc("profile.documentCount", files.length) }}
              </span>
            </template></v-file-input
          >
        </v-col>
        <v-col
          ><v-list v-if="files.length > 0" class="document-list">
            <v-subheader
              ><h5>{{ $t("profile.documents") }}</h5></v-subheader
            >

            <v-list-item v-for="(file, i) in files" :key="i">
              <v-list-item-avatar>
                <v-icon color="primary">$file</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ file.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="removeFile(i)">
                  <v-icon color="secondary">$delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list></v-col
        >
      </v-row>
    </v-form>
  </div>
</template>
<style lang="scss">
.document-list {
  border: 1px solid $primary !important;
  border-radius: 4px !important;
}
</style>
<script>
import {
  requiredValue,
  isMobilePhone,
  isTelephone,
  isAlphaNum,
  maxLength,
  minLength,
  IdDocumentType,
  isEmail,
  isName,
  isCAP
} from "~/validator/validationRules";
import CustomService from "@/service/customService";

export default {
  name: "NewCustomer",
  props: {
    isFieldDisabled: { type: Boolean, default: false },
    userData: { type: Object, required: false },
    response: { type: Object }
  },
  data() {
    return {
      isValid: false,
      warehouses: null,
      countries: null,
      provinces: null,
      bornCountry: null,
      birthDateCal: false,
      endDateDocCal: false,
      birthDate: null,
      endDateDoc: null,
      sameAddress: true,
      file: null,
      files: [],
      tmpDocuments: [],
      nations: [
        { id: 1, name: "Italia", code: "IT" },
        { id: 2, name: "Albania", code: "AL" },
        { id: 3, name: "Austria", code: "AT" },
        { id: 4, name: "Belgio", code: "BE" },
        { id: 5, name: "Bulgaria", code: "BG" },
        { id: 6, name: "Svizzera", code: "CH" },
        { id: 7, name: "Cina", code: "CN" },
        { id: 8, name: "Cipro", code: "CY" },
        { id: 9, name: "Repubblica Ceca", code: "CZ" },
        { id: 10, name: "Germania", code: "DE" },
        { id: 11, name: "Danimarca", code: "DK" },
        { id: 12, name: "Estonia", code: "EE" },
        { id: 13, name: "Grecia", code: "EL" },
        { id: 14, name: "Spagna", code: "ES" },
        { id: 15, name: "Finlandia", code: "FI" },
        { id: 16, name: "Francia", code: "FR" },
        { id: 17, name: "Gran Bretagna", code: "GB" },
        { id: 18, name: "Guinea Equatoriale", code: "GQ" },
        { id: 19, name: "Croazia", code: "HR" },
        { id: 20, name: "Ungheria", code: "HU" },
        { id: 21, name: "Irlanda", code: "IE" },
        { id: 22, name: "Lituania", code: "LT" },
        { id: 23, name: "Lussemburgo", code: "LU" },
        { id: 24, name: "Lettonia", code: "LV" },
        { id: 25, name: "Libia", code: "LY" },
        { id: 26, name: "Malta", code: "MT" },
        { id: 27, name: "Messico", code: "MX" },
        { id: 28, name: "Olanda", code: "NL" },
        { id: 29, name: "Norvegia", code: "NO" },
        { id: 30, name: "Polonia", code: "PL" },
        { id: 31, name: "Portogallo", code: "PT" },
        { id: 32, name: "Romania", code: "RO" },
        { id: 33, name: "Svezia", code: "SE" },
        { id: 34, name: "Slovenia", code: "SI" },
        { id: 35, name: "Repubblica Slovacca", code: "SK" },
        { id: 36, name: "San Marino", code: "SM" }
      ],
      provinceList: [],
      documentRules: [],
      requiredRules: requiredValue,
      mobilePhoneRules: [isMobilePhone()],
      signRules: [maxLength(100)],
      companyRules: [requiredValue(), maxLength(100)],
      secondaryEmailRules: [isEmail()],
      phoneRules: [requiredValue(), isMobilePhone()],
      workPhoneRules: [isMobilePhone()],
      telephoneNumber: [isTelephone()],
      nameRules: [requiredValue(), isName()],
      documentNumber: [requiredValue(), isAlphaNum()],
      documentTypeRules: [requiredValue(), IdDocumentType()],
      addressRules: [requiredValue(), maxLength(100)],
      postalcodeRules: [requiredValue(), isCAP()],
      vatSdiCodeRules: [
        minLength(6, "Il codice SDI deve essere lunga almeno 6 caratteri"),
        maxLength(7, "Il codice SDI deve essere lunga massimo 7 caratteri")
      ],
      pickerDate: this.$dayjs()
        .subtract(18, "years")
        .format("YYYY-MM-DD")
    };
  },
  methods: {
    validate() {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.form) {
          _this.valid = _this.$refs.form.validate();
          _this.$emit("validate", _this.valid);
        }
      }, 200);
    },
    getProvinceList(country, homeDeliveryOnly) {
      CustomService.provinceList(country, homeDeliveryOnly).then(data => {
        this.provinceList = data;
      });
    },
    countryChanged(formPart) {
      if (formPart == "billingAddress") {
        if (this.userData.billingAddress.countryId == "1") {
          // this.userData.billingAddress.province = null;
          delete this.userData.billingAddress.province;
        } else {
          this.userData.billingAddress.province = "EE";
        }
      } else if (formPart == "defaultShippingAddress") {
        if (this.userData.defaultShippingAddress.countryId == "1") {
          // this.userData.defaultShippingAddress.province = null;
          delete this.userData.defaultShippingAddress.province;
        } else {
          this.userData.defaultShippingAddress.province = "EE";
        }
      }
    },
    dateSelected() {
      this.birthDateCal = false;
      // var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      // let dateMilisec = new Date(this.birthDate).getMilliseconds();
      // this.userData.person.birthDate = new Date(dateMilisec - tzoffset)
      //   .toISOString()
      //   .substring(0, 10);
      this.userData.person.birthDate = this.birthDate;
    },
    fileDateSelected() {
      this.endDateDocCal = false;
      this.userData.person.personInfos[7].value = this.endDateDoc;
    },
    async addFile() {
      if (this.file) {
        this.documentRules = [];
        if (this.file.size / 1024 > 10240) {
          this.documentRules = ["L'allegato non deve superare i 10MB "];
        } else {
          this.files.push(this.file);
          let document = {};
          document.id = Math.random();
          document.filename = this.file.name;
          document.size = this.file.size;
          let docBase64 = await this.toBase64(this.file);
          var splitData = docBase64.split("base64,");
          if (splitData.length > 1) {
            document.filetype = splitData[0];
            document.filetype =
              document.filetype.split("data:").length > 1
                ? document.filetype.split("data:")[1]
                : document.filetype;
            document.base64 = splitData[1];
          } else {
            document.base64 = splitData[0];
          }
          this.tmpDocuments.push(document);
          this.userData.documentList = this.tmpDocuments;
          this.file = null;
        }
      }
    },
    removeFile(index) {
      this.files.splice(index, 1);
      this.tmpDocuments.splice(index, 1);
      this.userData.documentList = this.tmpDocuments;
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
  },
  computed: {
    maxBirthDate() {
      return this.$dayjs()
        .subtract(18, "years")
        .format("YYYY-MM-DD");
    },
    fomatedBirthDate() {
      return this.birthDate
        ? this.$dayjs(this.birthDate).format("DD-MM-YYYY")
        : null;
    },
    fomatedEndDateDoc() {
      return this.endDateDoc
        ? this.$dayjs(this.endDateDoc).format("DD-MM-YYYY")
        : null;
    }
  },
  created() {
    this.getProvinceList(1, false);
  }
};
</script>
