<template>
  <div class="mb-5">
    <v-form v-model="isValid" ref="form" @submit.prevent>
      <v-row>
        <v-col cols="12" md="6">
          <label v-html="`${$t('profile.contacts.referencePointOfSale')} *`" />
          <v-select
            :placeholder="`${$t('profile.contacts.referencePointOfSale')}`"
            v-model="userData.person.personInfos[3].value"
            :items="warehouses"
            item-text="addressName"
            item-value="addressId"
            :rules="[requiredRules()]"
            required
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-checkbox
            v-model="userData.person.personInfos[0].value"
            hide-details="auto"
            :rules="checkboxRules"
            class="disclaimer-checkbox"
            required
            ><div slot="label">
              {{ $t("profile.disclaimer1") }}
              (<a
                @click.stop="$router.push('/page/utilizzo-partita-iva/')"
                class="primary--text text-decoration-none"
                >{{ $t("profile.disclaimer1Link") }}</a
              >)
            </div></v-checkbox
          >
          <v-checkbox
            v-model="userData.person.personInfos[1].value"
            hide-details="auto"
            :rules="checkboxRules"
            class="disclaimer-checkbox"
            ><div slot="label">
              {{ $t("profile.disclaimer2") }}
              (<a
                @click.stop="$router.push('/page/web-policy/')"
                class="primary--text text-decoration-none"
                >web policy</a
              >). {{ $t("profile.disclaimer2NotAccepted") }}
            </div></v-checkbox
          >
          <v-checkbox
            v-model="userData.person.personInfos[2].value"
            hide-details
            class="disclaimer-checkbox"
            ><div slot="label">
              {{ $t("profile.disclaimer3") }}
              (<a
                @click.stop="$router.push('/page/web-policy/')"
                class="primary--text text-decoration-none"
                >web policy</a
              >)
            </div></v-checkbox
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { requiredValue, isMobilePhone } from "~/validator/validationRules";
import { isCheckboxTrueBool } from "@/customValidationRules";
import AddressService from "~/service/addressService";

export default {
  name: "PersonInfos",
  props: {
    isFieldDisabled: { type: Boolean, default: false },
    userData: { type: Object, required: false },
    response: { type: Object }
  },
  data() {
    return {
      isValid: false,
      warehouses: null,
      countries: null,
      provinces: null,
      bornCountry: null,
      checkboxRules: [
        isCheckboxTrueBool(
          "È obbligatorio accettare questo consenso per procedere"
        )
      ],
      requiredRules: requiredValue,
      mobilePhoneRules: [isMobilePhone()]
    };
  },
  methods: {
    validate() {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.form) {
          _this.valid = _this.$refs.form.validate();

          _this.$emit("validate", _this.valid);
        }
      }, 200);
    },
    async findWarehouse() {
      this.warehouses = await AddressService.getAddressListByType("drive");
    }
  },
  created() {
    this.findWarehouse();
  }
};
</script>
